(function ($, window, document, undefined) {
  'use strict';

  // Create site var, form here, call scope variables and functions
  var site = {
    $root: $('html, body'),
    height: parseInt($(window).height()),
    width: parseInt($(window).width()),
    animationTime: 700,
    $nav: $('.nav'),
    $logo: $('#logo'),
    $menu: $('.menu'),
    $mMenu: $('.m-menu'),

    // functions what are called when site loads/button presses
    init: function () {
      // On load, scroll to top of page
      site.$root.animate({scrollTop: 0}, "slow");

      // On load, fade out shadow and deactivate
      $('.shadow').addClass('deactivate');

      window.setTimeout(function () {
        $('.shadow').addClass('gone');
      }, 2000);

      // On load, load in logo
      site.$logo.addClass('active');

      // On click logo, scroll to top of page
      site.$logo.click(function () {
        site.$root.animate({scrollTop: 0}, "slow");
        return false;
      });

      $('.logo').click(function () {
        site.$root.animate({scrollTop: 0}, "slow");
      });

      // Mobile menu animations
      site.$mMenu.click(function () {
        var el = $(this);

        if ($(el).hasClass('active')) {
          site.$menu.removeClass('show');
          $(el).removeClass('cross');
          window.setTimeout(function () {
            $(el).removeClass('active');

            if ($(el).hasClass('cross')) {
              site.$nav.addClass('active');
            } else {
              site.$nav.removeClass('active');
            }
          }, 250);
        } else {
          site.$menu.addClass('show');
          $(el).addClass('active');
          window.setTimeout(function () {
            $(el).addClass('cross');

            if ($(el).hasClass('cross')) {
              site.$nav.addClass('active');
            } else {
              site.$nav.removeClass('active');
            }
          }, 250);
        }
      });
    },

    resize:function () {

    },

    scroll:function () {
      site.$nav.removeClass('active');
      site.$mMenu.removeClass('active');
      site.$mMenu.removeClass('cross');
    }
  };

  // Call document ready, resize and scroll
  $(document).ready(function() {
    site.init();
  });

  $(window).resize(function() {
    site.resize();
  });

  $(window).scroll(function() {
    site.scroll();
  });
})(jQuery, window, document);
